import { CareContext } from "../../../providers/CareProvider";
import {
  featureFlags,
  AvailableFeatureFlags,
} from "../../../providers/FeatureFlags";
import { useContext, useState } from "react";
import useKeyboardShortcut from "use-keyboard-shortcut";

export const FeatureFlagSidebar = () => {
  // Only enable this on dev
  useKeyboardShortcut(
    ["Control", "Shift", "K"],
    () => {
      setShowSidebar(!showSidebar);
    },
    { overrideSystem: true },
  );

  const [showSidebar, setShowSidebar] = useState(false);

  const { state, dispatch } = useContext(CareContext);
  const flags = state.featureFlags;

  const toggleFlag = (flag: AvailableFeatureFlags) => {
    dispatch({
      type: "setFeatureFlag",
      payload: {
        ...state.featureFlags,
        [flag]: !state.featureFlags[flag],
      },
    });
  };

  return (
    <div
      className={`fixed right-0 top-0 z-40 h-full w-[35vw] bg-white duration-300 ease-in-out ${
        showSidebar ? "translate-x-0 " : "translate-x-full"
      }`}
    >
      <h3 className="mb-3 text-2xl font-semibold">Feature Flags</h3>
      <div className="flex flex-col gap-2">
        {featureFlags.map((key) => {
          return (
            <div key={key} className="flex items-center gap-2">
              <span>{key}</span>
              <input
                type="checkbox"
                key={key}
                id={key}
                value={key}
                checked={flags[key]}
                onChange={() => toggleFlag(key)}
              />
            </div>
          );
        })}
      </div>
      <div className="absolute bottom-0 right-0 p-2 text-red-600">
        <span>Remember: Toggle with Ctrl+Shift+F</span>
      </div>
    </div>
  );
};
